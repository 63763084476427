import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import { IndexPageQuery } from '../graphqlTypes'

import TagTemplate from '../templates/tag'

export type IndexPageProps = PageProps<IndexPageQuery>

export const IndexPage = (props: IndexPageProps) => <TagTemplate {...props} />

export default IndexPage

export const query = graphql`
  query IndexPage {
    allFile(
      filter: { sourceInstanceName: { eq: "posts" }, base: { eq: "index.md" } }
      sort: { fields: [childMarkdownRemark___frontmatter___date], order: DESC }
    ) {
      nodes {
        childMarkdownRemark {
          ...Post
        }
      }
    }
    ...Tags
  }
`
